import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage1 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 01</h3>
          <h1>International Functional Fitness Federation</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              The International Functional Fitness Federation (iF3) is the
              non-profit governing body serving the legal framework to bring
              Functional Fitness to the Olympic Games. We provide international
              leadership, structure, and resources to fuel the growth of
              functional fitness as a sport as well as enrich the experience and
              safety of its worldwide participants.
            </p>

            <p>
              Our mission is to create a pathway for competitive functional
              fitness to be included in the Olympic Games by developing a
              governance structure which provides rules, safety standards,
              Technical Official training, and competitive opportunities for
              athletes.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/2">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage1
